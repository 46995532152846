<!-- eslint-disable max-len -->
<template>
  <div class="contact-form londo-form">
    <LondoInput
      v-model="message"
      type="textarea"
      placeholder="Ask us any questions you might have"
      inputName="extra-info-message"
      :multiline="true"
      :inputId="extraInfoStateScope + '-enquiry-message'"
      @click="onInputClick('extra-info-message')"
    />

    <div class="londo-switch-group">
      <LondoSwitch
        :id="referredRef"
        v-model="referred"
        fieldName="was-referred"
      />
      <label
        class="londo-switch-group__label"
        :for="extraInfoStateScope + '-referred'"
      >
        Have you been referred to us?
      </label>
    </div>

    <div class="londo-switch-group">
      <LondoSwitch
        :id="extraInfoStateScope + '-agent'"
        v-model="travelAgent"
        fieldName="travel agent"
      />
      <label
        class="londo-switch-group__label"
        :for="extraInfoStateScope + '-agent'"
      >
        Are you working with a travel agent?
      </label>
    </div>

    <div class="londo-switch-group">
      <LondoSwitch
        :id="extraInfoStateScope + '-booked-flights'"
        fieldName="flights_booked"
        v-model="bookedFlights"
      />
      <label class="londo-switch-group__label">
        Have you booked your flights yet?
      </label>
    </div>

    <div class="budget-wrapper">
      <LondoSelect
        v-model="budget"
        inputName="budget"
        :label="wordingPerSize[grid.breakpoint] || 'Budget?'"
        :inputId="extraInfoStateScope + '-budget'"
        @mousedown="onInputClick('budget')"
      >
        <template v-slot:options>
          <option />
          <option value="R95,000 - R130,000">R95,000 - R130,000</option>
          <option value="R130,000 - R160,000">R130,000 - R160,000</option>
          <option value="R160,000+">R160,000 +</option>
        </template>
      </LondoSelect>
    </div>

    <div v-if="signupHeading" class="londo-form__title h3">
      {{ signupHeading }}
    </div>

    <div class="sub-options londo-checkbox-group">
      <label class="input-label input-label-checkbox">
        <input
          type="checkbox"
          class="input-checkbox"
          name="daily-subscribe"
          @click="onInputClick('daily-subscribe')"
          v-model="daily"
          :value="daily ? 'on' : 'off'"
        />
        Daily Story
      </label>

      <label class="input-label input-label-checkbox">
        <input
          type="checkbox"
          v-model="monthly"
          :value="monthly ? 'on' : 'off'"
          @click="onInputClick('monthly-subscribe')"
          class="input-checkbox"
          name="monthly-subscribe"
        />
        Monthly Newsletter
      </label>
    </div>
  </div>
</template>

<script>
import LondoSwitch from "@/components/Forms/Switch.vue";
import LondoSelect from "@/components/Forms/Select.vue";
import LondoInput from "@/components/Forms/Input.vue";
import enquiryActions from "@/store/modules/enquiry-mutation-types";

export default {
  name: "LondoExtraInformationForm",
  components: {
    LondoSwitch,
    LondoInput,
    LondoSelect,
  },
  props: {
    extraInfoState: {
      type: [Object],
      required: true,
    },
    extraInfoStateScope: {
      type: String,
      required: true,
    },
    signupHeading: {
      type: String,
    },
  },
  data() {
    return {
      actionMapping: enquiryActions,
      wordingPerSize: {
        base: "Budget?",
        xs: "Budget?",
        sm: "Your budget?",
        md: "Your budget?",
        lg: "What is your budget?",
        xl: "What is your budget?",
        xxl: "What is your budget?",
        xxxl: "What is your budget?",
      },
    };
  },
  methods: {
    onInputClick(fieldName) {
      this.$emit("fieldClicked", fieldName);
    },
  },
  computed: {
    referredRef() {
      return `${this.extraInfoStateScope}-referred`;
    },
    referred: {
      get() {
        return this.extraInfoState.referred;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.extraInfoStateScope].extra.referred,
          value
        );
        this.onInputClick('referred');
      },
    },
    travelAgent: {
      get() {
        return this.extraInfoState.travelAgent;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.extraInfoStateScope].extra.travelAgent,
          value
        );
        this.onInputClick('travel-agent');
      },
    },
    bookedFlights: {
      get() {
        return this.extraInfoState.bookedFlights;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.extraInfoStateScope].extra.bookedFlights,
          value
        );
        this.onInputClick('flights booked');
      },
    },
    budget: {
      get() {
        return this.extraInfoState.budget;
      },
      set(event) {
        this.$store.commit(
          this.actionMapping[this.extraInfoStateScope].extra.budget,
          event.target.value
        );
      },
    },
    message: {
      get() {
        return this.extraInfoState.message;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.extraInfoStateScope].extra.message,
          value
        );
      },
    },
    daily: {
      get() {
        return this.extraInfoState.daily;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.extraInfoStateScope].extra.daily,
          value
        );
      },
    },
    monthly: {
      get() {
        return this.extraInfoState.monthly;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.extraInfoStateScope].extra.monthly,
          value
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.budget-wrapper {
  margin: 1rem 0 3rem 0;
}

.londo-form {
  .londo-form__title {
    @include separator-h4;
    margin-top: 2rem;
    margin-bottom: 0.7rem;
  }
}
</style>
