<!-- eslint-disable max-len -->
<template>
  <div class="relative">
    <LondoInput
      :label="label"
      :readonly="true"
      :displayValue="value"
      :error="error"
      @click.stop="togglePopover"
    />

    <div class="multiselect fade-up" v-show="showPicker">
      <slot name="popover-content" />
      <ButtonRound
        @click.stop="$emit('buttonPressed')"
        hoverText="Done"
        type="button"
        icon="check"
        class="btn-multiselect"
      />
    </div>
  </div>
</template>

<script>
import LondoInput from "@/components/Forms/Input.vue";
import ButtonRound from "@/components/Buttons/ButtonRound.vue";

export default {
  name: "LondoInputMultiSelect",
  props: {
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
    showPicker: {
      required: true,
      default: false,
      type: Boolean,
    },
    error: {
      required: false,
      type: String,
    },
  },
  methods: {
    onChange(event) {
      this.$emit("input", event);
    },
    handleClick(event) {
      this.$emit("click", event);
    },
    togglePopover(event) {
      this.$emit("popover-toggled");
      this.handleClick(event);
    },
  },
  components: {
    LondoInput,
    ButtonRound,
  },
};
</script>

<style lang="scss">
.multiselect {
  @apply z-30 bg-white absolute right-0 shadow-lg flex flex-col px-6;
  width: 250px;
  top: 40px;
  border-top: 2px solid $blue;
  padding-bottom: 3.7rem;
  .btn-multiselect {
    @apply absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  &__row {
    @apply flex pt-4 pb-0;
    label {
      @apply flex flex-col flex-shrink justify-center w-1/2;
      @include input-label;
      line-height: 1.3;
      span {
        @apply text-sm italic font-serif capitalize tracking-normal text-grey-300;
      }
    }
  }
}

@screen xs {
  .multiselect {
    width: 280px;
    top: 44px;
  }
}
@screen md {
  .multiselect {
    width: 300px;
  }
}
</style>
