<template>
  <button
    class="btn btn-blue btn-book"
    :disabled="disabled"
    :class="{ 'btn-book--in-progress': buttonProgress }"
    @click="handleClick"
  >
    {{ buttonText }}
  </button>
</template>

<script>
/* eslint-disable operator-linebreak */
import { mapMutations, mapState } from "vuex";
import pageMapping, {
  pageToPageId,
  parentIdToChildRouteName,
} from "@/util/pageMapping";
import scrollToAnchor from "@/mixins/scrollToAnchor";
import tracking from "@/mixins/tracking";
const enquireHashLiteral = "#enquire";

const defaultText = "Book Now";

const tourSinglePageRoute =
  parentIdToChildRouteName[pageToPageId[pageMapping.tours]];
const retreatSinglePageRoute =
  parentIdToChildRouteName[pageToPageId[pageMapping.retreats]];

export default {
  name: "LondoButtonBook",
  mixins: [scrollToAnchor, tracking],
  props: {
    text: {
      type: String,
      default: defaultText,
    },
    disabled: {
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapState({
      bookingStepIndex: (state) => state.enquiry.booking.stepIndex,
    }),
    buttonText() {
      const { name: currentRouteName } = this.$route;

      if (
        this.text !== defaultText ||
        currentRouteName === tourSinglePageRoute ||
        currentRouteName === retreatSinglePageRoute
      ) {
        return this.text;
      }

      if (this.bookingStepIndex > 0) {
        return "Your Booking";
      }

      return this.text;
    },
    buttonProgress() {
      return this.buttonText === "Your Booking";
    },
  },
  methods: {
    ...mapMutations(["openDrawerBooking"]),
    handleClick() {
      const { name: currentRouteName } = this.$route;

      this.trackEvent({ event: "book_now_cta" });

      if (
        currentRouteName === tourSinglePageRoute ||
        currentRouteName === retreatSinglePageRoute
      ) {
        if (this.$route.hash !== enquireHashLiteral) {
          this.$router.replace({ hash: enquireHashLiteral });
        }
        this.scrollToAnchor();
      } else {
        this.openDrawerBooking();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-book {
  &--in-progress {
    @apply px-4;
  }
}
.app--perspective {
  .btn-book {
    &:focus {
      outline: none;
    }
  }
}
</style>
