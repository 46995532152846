<template>
  <div class="number-picker">
    <button type="button" class="number-picker__btn" @click.stop="decrement">
      -
    </button>
    <input
      type="number"
      min="0"
      readonly="true"
      class="number-picker__input"
      :name="fieldName"
      :value="modelValue"
      @change="handleChange"
    />
    <button type="button" class="number-picker__btn" @click.stop="increment">
      +
    </button>
  </div>
</template>

<script>
export default {
  name: "LondoNumberPicker",
  props: {
    modelValue: {
      type: Number,
      required: true,
      default: 0,
    },
    fieldName: {
      type: String,
    },
  },
  methods: {
    increment() {
      this.emitChange(this.modelValue + 1);
    },
    decrement() {
      const newVal = this.modelValue - 1;
      this.emitChange(newVal < 0 ? 0 : newVal);
    },
    handleChange(event) {
      this.emitChange(event.target.value);
    },
    emitChange(value) {
      this.$emit("update:modelValue", parseInt(value, 10));
    },
  },
};
</script>

<style lang="scss" scoped>
.number-picker {
  @apply flex justify-end;
  &__btn,
  &__input {
    @apply flex items-center justify-center text-center;
    height: 40px;
    width: 40px;
  }
  &__btn {
    @apply text-2xl font-sans flex justify-center items-center leading-normal;
    @include transition-base;
    border: 1px solid $grey-200;
    &:hover {
      border-color: $grey;
    }
  }
  &__input {
    border-top: 1px solid $grey-200;
    border-bottom: 1px solid $grey-200;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -webkit-appearance: none;
    border-radius: 0;
    -moz-appearance: textfield;
  }
}

@screen xs {
  .number-picker {
    &__btn,
    &__input {
      top: 44px;
    }
  }
}
</style>
