<template>
  <div class="datepicker-wrap relative" ref="datepickerWrap">
    <LondoInput
      :label="label"
      :readonly="true"
      :modelValue="modelValue"
      :inputId="inputId"
      :error="error"
      :ref="inputId"
      :inputName="fieldName"
      @input="handleInput"
      @click="handleClick"
    />
  </div>
</template>

<script>
/* eslint-disable max-len */
import LondoInput from "@/components/Forms/Input.vue";

export default {
  props: {
    pikadayOptions: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    inputId: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
    },
    error: {
      type: String,
    },
  },
  data() {
    return {
      pikaday: null,
    };
  },
  components: {
    LondoInput,
  },
  methods: {
    handleInput(value) {
      this.$emit("update:modelValue", value);
    },
    handleClick(event) {
      event.stopPropagation();
      this.$emit("click", event);
    },
  },
  async mounted() {
    if (process.client) {
      const el = this.$refs[this.inputId]?.$refs[this.inputId];
      const module = await import("pikaday");
      this.pikaday = new module.default({
        field: el,
        position: "bottom right",
        ...this.pikadayOptions,
      });
    }
  },
  watch: {
    pikadayOptions(newOptions, oldOptions) {
      console.log(
        "%c%s",
        "color:DarkGoldenRod;",
        "pikaday options changed, reinitialising",
        oldOptions,
        newOptions,
      );

      if (newOptions.maxDate) {
        this.pikaday.setMaxDate(newOptions.maxDate);
      }

      if (newOptions.minDate) {
        this.pikaday.setMinDate(newOptions.minDate);
        this.pikaday.gotoDate(newOptions.minDate);
      }
    },
  },
  beforeDestroy() {
    if (this.pikaday) {
      this.pikaday.destroy();
    }
  },
};
</script>

<style lang="scss">
.pika-single:before,
.pika-single:after {
  content: " ";
  display: table;
}
.pika-single:after {
  clear: both;
}

.pika-single.is-hidden {
  display: none;
}

.pika-single.is-bound {
  position: absolute;
}

.pika-label {
  display: inline-block;
  position: relative;
  z-index: 9999;
  overflow: hidden;
  margin: 0;
  background-color: #fff;
}
.pika-title select {
  position: absolute;
  z-index: 9998;
  margin: 0;
  left: 0;
  opacity: 0;
}

.pika-prev,
.pika-next {
  cursor: pointer;
  position: relative;
  outline: none;
  border: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0.5;
}

.pika-select {
  display: inline-block;
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}

.pika-table th,
.pika-table td {
  width: 14.285714285714286%;
  padding: 0;
}

.pika-table th {
  text-align: center;
}

.pika-button {
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  outline: none;
  border: 0;
  margin: 0;
  width: 100%;
}

.pika-week {
  font-size: 11px;
  color: #999;
}

.is-today .pika-button {
  font-weight: bold !important;
}

.is-selected .pika-button,
.has-event .pika-button {
  color: #fff;
  font-weight: bold;
  background: $blue;
}

.is-inrange .pika-button {
  background: #d5e9f7;
}

.is-startrange .pika-button {
  color: #fff;
  background: #6cb31d;
  box-shadow: none;
}

.is-endrange .pika-button {
  color: #fff;
  background: #33aaff;
  box-shadow: none;
}

.is-outside-current-month .pika-button {
  color: #999;
  opacity: 0.3;
}

.is-selection-disabled {
  pointer-events: none;
  cursor: default;
}

.pika-button:hover,
.pika-row.pick-whole-week:hover .pika-button {
  color: #fff;
  background: $blue;
  box-shadow: none;
}

/* styling for abbr */
.pika-table abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.pika-single {
  z-index: 100;
  &.-is-hidden {
    animation: none;
  }
  @include animation-fade-up;
  @apply shadow-lg font-serif block relative;
  border: none;
  border-top: 2px solid $blue;
  font-size: 1rem;
  background: white;
  .pika-lendar {
    width: 250px;
    padding: 0 0 0.6em;
    .pika-title {
      @apply font-din font-bold uppercase flex items-center;
      font-size: 1.4em;
      .pika-label {
        order: 2;
        padding: 0 0.1em;
      }
      select {
        @apply shadow-md text-base w-full h-full cursor-pointer;
      }
    }
    .pika-prev,
    .pika-next {
      @apply relative h-full flex flex-col justify-center items-center px-4;
      color: transparent;
      height: 60px;
      font-size: 0;
      @include transition-base;
      &:hover {
        opacity: 1;
      }
      &.is-disabled {
        cursor: default;
        opacity: 0.2;
      }
      &:after {
        @include font-icon;
        @apply flex items-center justify-center;
        color: black;
        font-size: 1.4rem;
      }
      &.pika-next {
        @apply ml-auto;
        order: 5;
        &:after {
          content: "\64";
          left: 0;
        }
      }
      &.pika-prev {
        @apply mr-auto;
        order: 1;
        &:after {
          content: "\63";
        }
      }
    }
    .pika-table {
      thead {
        border-color: $grey-100;
        border-width: 0.2em 0.6em;
        border-style: solid;
        th {
          @apply bg-grey-100 italic font-normal leading-loose text-grey-500;
          font-size: 0.9em;
          padding: 0.6em 0;
        }
      }
      tbody {
        border-color: white;
        border-width: 0.2em 0;
        border-style: solid;
      }
      tr {
        td {
          height: 34.6px;
          padding: 0.2em;
          .pika-button {
            @include transition-base;
            height: 100%;
            border-radius: 50%;
            font-size: 0.9em;
          }
          &:last-child,
          &:nth-child(6) {
            color: $blue;
          }
          &.is-disabled {
            .pika-button {
              pointer-events: none;
              cursor: default;
              text-decoration: line-through;
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
}

@screen xs {
  .pika-single {
    .pika-lendar {
      width: 280px;
      .pika-table {
        tr {
          td {
            height: 38.7px;
          }
        }
      }
    }
  }
}
@screen md {
  .pika-single {
    .pika-lendar {
      width: 300px;
      .pika-table {
        tr {
          td {
            height: 41.6px;
          }
        }
      }
    }
  }
}
</style>
