const TABS_LITERAL = "tabs";

export default {
  methods: {
    scrollToAnchor(anchorId = TABS_LITERAL) {
      document.getElementById(anchorId).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
