<!-- eslint-disable max-len -->
<template>
  <div class="contact-form londo-form">
    <DatePicker
      label="Arrival"
      v-model="arrival"
      fieldName="arrival-date"
      :inputId="inputIdPrefix + '-arrival-picker'"
      :pikadayOptions="arrivalDateOptions"
      :error="arrivalError"
      @click="onInputClick('arrival-date')"
    />

    <DatePicker
      label="Departure"
      v-model="departure"
      fieldName="departure-date"
      :inputId="inputIdPrefix + '-departure-picker'"
      :pikadayOptions="departureDateOptions"
      :error="departureError"
      @click="onInputClick('departure-date')"
    />

    <MultiSelect
      label="Guests"
      :value="totalGuests"
      :showPicker="guestsOpen"
      :error="guestsError"
      @popover-toggled="handleGuestsPopoverChange"
      @buttonPressed="handleGuestsPopoverChange"
    >
      <template v-slot:popover-content>
        <div class="multiselect__row">
          <label>Adults</label>
          <NumberPicker v-model="adults" fieldName="noadults" />
        </div>
        <div class="multiselect__row">
          <label>Children <span>Ages 6-11</span></label>
          <NumberPicker v-model="children" fieldName="nochildren" />
        </div>
        <div class="multiselect__row">
          <label>Children <span>Ages 12-16</span></label>
          <NumberPicker v-model="children1216" fieldName="nochildren1216" />
        </div>
      </template>
    </MultiSelect>

    <div class="londo-switch-group" v-if="beenBefore !== undefined">
      <LondoSwitch
        :id="inputIdPrefix + '-been-before'"
        fieldName="been-before"
        v-model="beenBefore"
      />
      <label class="londo-switch-group__label">
        Have you been to Londolozi before?
      </label>
    </div>

    <LondoInput
      v-if="message !== undefined"
      v-model="message"
      label="Notes"
      placeholder="Is there anything else we should know?"
      type="textarea"
      fieldName="additional-info"
      :multiline="true"
      :inputId="inputIdPrefix + '-message'"
    />
  </div>
</template>

<script>
/* eslint-disable indent */
/* eslint-disable space-before-function-paren */

import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';
import {toRaw} from 'vue';
import dayjs from "dayjs";
import DatePicker from "@/components/Forms/DatePicker.vue";
import NumberPicker from "@/components/Forms/NumberPicker.vue";
import MultiSelect from "@/components/Forms/InputMultiSelect.vue";
import LondoSwitch from "@/components/Forms/Switch.vue";
import LondoInput from "@/components/Forms/Input.vue";
import enquiryActions from "@/store/modules/enquiry-mutation-types";

export default {
  name: "LondoBookingDetailsForm",
  setup: () => ({ v$: useVuelidate() }),
  components: {
    DatePicker,
    NumberPicker,
    MultiSelect,
    LondoSwitch,
    LondoInput,
  },
  props: {
    filterState: {
      type: [Object],
      required: true,
    },
    filterStateScope: {
      type: String,
      required: true,
    },
    inputIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      guestsOpen: false,
      actionMapping: enquiryActions,
      isValid: false,
    };
  },
  computed: {
    btnDisabled() {
      return !this.isValid;
    },
    arrivalDateOptions() {
      return {
        minDate: dayjs().add(1, "day").toDate(),
        maxDate: dayjs().add(2, "year").endOf("year").toDate(),
      };
    },
    departureDateOptions() {
      const minDate = this.arrival
        ? dayjs(this.arrival).add(1, "day").toDate()
        : dayjs().add(2, "day").toDate();

      return {
        minDate,
        maxDate: dayjs().add(2, "year").endOf("year").toDate(),
      };
    },
    arrival: {
      get() {
        return this.filterState.arrival;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.filterStateScope].filters.arrival,
          value,
        );

        this.v$.arrival.$touch();
        this.setStoreValid();
      },
    },
    departure: {
      get() {
        return this.filterState.departure;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.filterStateScope].filters.departure,
          value,
        );

        this.v$.departure.$touch();
        this.setStoreValid();
      },
    },
    adults: {
      get() {
        return this.filterState.adults;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.filterStateScope].filters.adults,
          value,
        );

        this.v$.adults.$touch();
        this.setStoreValid();
      },
    },
    children: {
      get() {
        return this.filterState.children;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.filterStateScope].filters.children,
          value,
        );

        this.v$.children.$touch();
        this.setStoreValid();
      },
    },
    children1216: {
      get() {
        return this.filterState.children1216;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.filterStateScope].filters.children1216,
          value,
        );

        this.v$.children1216.$touch();
        this.setStoreValid();
      },
    },
    beenBefore: {
      get() {
        return this.filterState.beenBefore;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.filterStateScope].filters.beenBefore,
          value,
        );
        this.onInputClick('returning customer');
      },
    },
    message: {
      get() {
        return this.filterState.message;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.filterStateScope].filters.message,
          value,
        );
      },
    },
    totalGuests() {
      const total = this.children + this.children1216 + this.adults;
      if (total === 0) {
        return "";
      }

      return `${total} guest${total > 1 ? "s" : ""}`;
    },
    guestsError() {
      if (this.v$.adults.minValue.$invalid) {
        return "At least one adult is required";
      }

     if (this.v$.guests.maxTotalGuests.$invalid) {
        return "Maximum of 20 guests per booking";
      }

      return "";
    },
    arrivalError() {
      if (this.v$.arrival.$dirty && this.v$.arrival.minValue.$invalid) {
        return "Arrival date must be after today";
      }

      return "";
    },
    departureError() {
      if (this.v$.departure.$dirty && this.v$.departure.minValue.$invalid) {
        return "Departure date must be after arrival date";
      }

      return "";
    },
  },
  methods: {
    handleGuestsPopoverChange() {
      this.guestsOpen = !this.guestsOpen;
      this.onInputClick('guests');
    },
    onInputClick(fieldName) {
      this.$emit("fieldClicked", fieldName);
    },
    setStoreValid: function() {
      const newValid = !this.v$.$invalid;
      this.isValid = newValid;
      this.$store.commit(
        this.actionMapping[this.filterStateScope].filters.valid,
        newValid,
      );
    }
  },
  validations: {
    arrival: {
      required,
      minValue: (value) => dayjs(value).isAfter(dayjs()),
    },
    departure: {
      required,
      minValue(value) {
        if (!this.arrival) {
          return true;
        }

        return dayjs(value).isAfter(dayjs(this.arrival));
      },
    },
    adults: {
      required,
      minValue: minValue(1),
    },
    children: {
      required,
    },
    children1216: {
      required,
    },
    guests: {
      maxTotalGuests() {
        const total = this.children + this.children1216 + this.adults;

        if (total > 20) {
          return false;
        }

        return true;
      },
    },
  },
};
</script>

<style lang="scss">
// not sure why this modifier is required - TODO address during styling
.pika-single {
  z-index: 50;
}
</style>
